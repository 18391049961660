<template>
  <div class="user-home flex-auto flex-column">
    <div class="flex-row">
      <div class="welcome flex-column align-items-center justify-center">
        <div style="flex:0 0 50px;">
          <el-badge :value="$store.state.loginRes.audit_state==2?'已认证':'未认证'" :type="$store.state.loginRes.audit_state==2?'success':'info'" :hidden="$store.getters.isSpecUser">
            <el-tooltip class="item" effect="dark" content="设置头像" placement="top-start">
              <el-upload
                class="avatar-uploader"
                action=""
                :http-request="setProfile"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload">
                <el-image v-if="imageUrl" :src="imageUrl" class="avatar" fit="cover"></el-image>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-tooltip>
          </el-badge>
        </div>
        <div class="flex-row align-items-center justify-center margin-top-5 margin-lr-15">
          <div class="font-size-16 bold  text-overflow-ellipsis-block-2">
            {{ $store.state.loginRes.dwmch||$store.state.loginRes.user_name}}
          </div>
        </div>
        <div class="flex-row align-items-center justify-center margin-top-5 margin-lr-15 border-top padding-top-5" style="width:90%" >
          <div class="font-size-12">客户编码：{{$store.state.loginRes.dwbh}}
          </div>
        </div>
         
        <div class="flex-row margin-top-10">
          <div class="custom-btn link margin-right-20" color="gray" @click="$root.jump({path:'/user/authentication'})">资料认证</div>
          <div class="custom-btn link" color="gray" @click="$root.jump({path:'/user/address'})">地址管理</div>
        </div>
      </div>
      <div class="column flex-auto flex-column" style="margin:15px 0; padding:0; border-radius: 0 10px 10px 0/0 10px 10px 0;">
        <div class="border-bottom padding-tb-10 padding-lr-20">
          我的授信
          <el-popover placement="right" width="350" trigger="hover">
            <div class="line-height-medium">
              普通总额度=基础额度+临时额度
            </div>
            <span slot="reference"><i class="el-icon-info link font-size-14 margin-left-5"></i></span>
          </el-popover>
        </div>
        <div class="flex-row align-items-center justify-between flex-auto">
          <div class="text-align-center padding-lr-40">
            <div class="font-size-24 color-orange bold">{{credit.general_all_limit}}</div>
            <div class="font-size-12">普通总额度</div>
          </div>
          <div class="text-align-center padding-lr-40">
            <div class="font-size-24 color-orange bold">{{credit.general_available_limit}}</div>
            <div class="font-size-12">普通可用额度</div>
          </div>
          <div class="text-align-center padding-lr-40">
            <div class="font-size-24 color-orange bold">{{credit.mhj_all_limit}}</div>
            <div class="font-size-12">麻黄碱额度</div>
          </div>
          <div class="text-align-center padding-lr-40">
            <div class="font-size-24 color-orange bold">{{credit.mhj_available_limit}}</div>
            <div class="font-size-12">麻黄碱可用额度</div>
          </div>
        </div>
      </div>
    </div>
    <OrderList class="margin-top-20"></OrderList>
  </div>
</template>

<script>
import crypto from "@/common/crypto";
import avatar from "@/assets/images/default-photo.png";
import OrderList from './OrderList.vue'
export default {
  components: {OrderList },
  name: 'UserHome',
  data() {
    return {
     avatar,
     imageUrl: this.$store.state.loginRes.user_profile||avatar,
     credit:{}
    }
  },
  computed:{

  },
  methods:{
    //获取授信额度
    getCredit(){
      let params= {
        ...this.$store.state.basicParams,
      }
      this.$instance.get('/user/my_credit',{params})
      .then(res=>{
        if(res.data.code==0){
          this.credit=res.data.data
        }
      })
      .catch(()=> {}); 
    },
    //设置头像
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    setProfile(obj){
      console.log(obj)
      const formData = new FormData();
      formData.append( "user_profile" , obj.file );//file 和 后端字段要保持一致
      this.$instance.post('/user/setting_user_profile',formData,
        {
          timeout: 300000,
        } 
      ).then(res=>{
        if(res.data.code==0){
          this.imageUrl = URL.createObjectURL(obj.file);
          this.$store.commit('loginRes',{...this.$store.state.loginRes,user_profile:this.imageUrl})
          try{
            localStorage.setItem('loginRes',crypto.encrypt(this.$store.state.loginRes))
          }catch{
            //
          }
          
          this.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        }
      })
    },
    
  },
  created(){
    this.getCredit()
  },
  mounted(){ 
   
  },
  beforeDestroy() {
  
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .welcome{
  flex:0 0 300px;
  height: 200px;
  background: url('../../assets/images/user-welcome.jpg') center no-repeat;
  background-size:cover;
  color:#fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
   .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
  .avatar {
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 100%;
  }
 }
</style>
<style>

</style>
